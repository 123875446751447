<template>
  <v-card class="bar" width="20vw" tile elevation="0 pa-0" color="rgba(0,0,0,0)">
    <v-card class="bar" :width="`${percentage}%`" elevation="0" :color="color">
      <span>
        <br />
      </span>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "#fff",
    },
    number: {
      type: Number,
      default: 598,
    },
  },
  data() {
    return {
      percentage: 0,
    };
  },
  watch: {
    size(newVal) {
      this.percentage = (newVal / this.number) * 100;
    },
  },
};
</script>

<style lang="scss">
.bar {
  width: 0%;
  transition: width 2s !important;
}
</style>
