<template>
  <v-container elevation="0" class="text-center pt-0" v-resize="onResize">
    <v-card elevation="0">
      <!-- headline -->
      <v-row class="pt-0">
        <v-col class="py-0 mt-5">
          <v-card-title class="py-0 text-left welcome">
            Herzlich Willkommen beim #Mandaterechner zur Berechnung der
            Bundestagsgröße
          </v-card-title>
        </v-col>
      </v-row>
      <!-- intro text -->
      <v-row>
        <v-col class="d-flex align-start px-7 py-sm-4 py-3">
          <span class="text-left lato">
            <info-text :arg="infoText" />
          </span>
        </v-col>
      </v-row>
      <!-- buttons -->
      <v-item-group mandatory v-model="selected" v-show="isMobile">
        <v-row class="px-sm-7 px-3">
          <v-col cols="6" sm="6">
            <v-item v-slot="{ active, toggle }">
              <v-btn
                :outlined="!active"
                dark
                tile
                color="#012e87"
                class="lato btn-txt"
                :width="btnSize"
                :class="{ bold: active }"
                @click="toggle"
              >{{ btnText.first }}</v-btn>
            </v-item>
          </v-col>
          <v-col cols="6" sm="6">
            <v-item v-slot="{ active, toggle }">
              <v-btn
                :outlined="!active"
                dark
                tile
                color="#012e87"
                class="lato btn-txt"
                :width="btnSize"
                :class="{ bold: active }"
                @click="toggle"
              >{{ btnText.second }}</v-btn>
            </v-item>
          </v-col>
          <v-col cols="6" sm="6">
            <v-item v-slot="{ active, toggle }">
              <v-btn
                :outlined="!active"
                dark
                tile
                color="#012e87"
                class="lato btn-txt"
                :width="btnSize"
                :class="{ bold: active }"
                @click="toggle"
              >{{ btnText.third }}</v-btn>
            </v-item>
          </v-col>
          <v-col cols="6" sm="6">
            <v-item v-slot="{ active, toggle }">
              <v-btn
                :outlined="!active"
                dark
                tile
                color="#012e87"
                class="lato btn-txt"
                :width="btnSize"
                :class="{ bold: active }"
                @click="toggle"
              >{{ btnText.fourth }}</v-btn>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
      <!-- Bundestag -->
      <v-row>
        <v-col
          v-if="!isMobile"
          class="d-flex flex-column align-center justify-space-between"
          style="flex-grow: 0"
        >
          <v-item-group mandatory v-model="selected" class="pl-2 pb-0" v-show="!isMobile">
            <v-col cols="12" sm="3" class="pt-0">
              <v-item v-slot="{ active, toggle }">
                <v-btn
                  :outlined="!active"
                  dark
                  tile
                  color="#012e87"
                  class="lato btn-txt"
                  :width="btnSize"
                  :class="{ bold: active }"
                  @click="toggle"
                >{{ btnText.first }}</v-btn>
              </v-item>
            </v-col>
            <v-col cols="12" sm="3">
              <v-item v-slot="{ active, toggle }">
                <v-btn
                  :outlined="!active"
                  dark
                  tile
                  color="#012e87"
                  class="lato btn-txt"
                  :width="btnSize"
                  :class="{ bold: active }"
                  @click="toggle"
                >{{ btnText.second }}</v-btn>
              </v-item>
            </v-col>
            <v-col cols="12" sm="3">
              <v-item v-slot="{ active, toggle }">
                <v-btn
                  :outlined="!active"
                  dark
                  tile
                  color="#012e87"
                  class="lato btn-txt"
                  :width="btnSize"
                  :class="{ bold: active }"
                  @click="toggle"
                >{{ btnText.third }}</v-btn>
              </v-item>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-item v-slot="{ active, toggle }">
                <v-btn
                  :outlined="!active"
                  dark
                  tile
                  color="#012e87"
                  class="lato btn-txt"
                  :width="btnSize"
                  :class="{ bold: active }"
                  @click="toggle"
                >{{ btnText.fourth }}</v-btn>
              </v-item>
            </v-col>
          </v-item-group>
        </v-col>
        <v-col class="d-flex flex-column justify-center py-0">
          <v-card elevation="0" tile class="ov-wrapper">
            <bundestag-chart v-show="dataReady" class="behind" ref="chart" :chartData="chartData"></bundestag-chart>
            <img
              class="schatten"
              ref="shadow"
              v-show="size > baseSize"
              src="../assets/schatten.svg"
            />
            <img class="illu" ref="illu" src="../assets/illu.svg" />
          </v-card>
          <h1 v-if="!isMobile" class="h1">
            <animate-number :number="number" />
          </h1>
        </v-col>
      </v-row>

      <!-- Zahlen -->
      <v-row v-if="isMobile">
        <v-col class="py-0">
          <h1 class="h1">
            <animate-number :number="number" />
          </h1>
        </v-col>
      </v-row>

      <!-- table -->
      <v-row>
        <v-col class="px-7 pt-0">
          <v-data-table
            class="mytable lato"
            mobile-breakpoint="0"
            disable-sort
            :loading="loading"
            hide-default-footer
            :headers="headers"
            :items="parteien"
            item-key="name"
          >
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:body="{ items }">
              <tbody class="text-left lato">
                <tr v-for="(item, index) in items" :key="index">
                  <td class="myTD">{{ item.name }}</td>
                  <td class="myTD">{{ item.second_votes }}</td>
                  <td class="myTD">
                    <span v-if="index != 7">
                      <animate-number class="bold" :number="item.all_seats" />
                    </span>
                    <animate-number class="bold" v-else :number="number" />
                  </td>
                  <td class="noGuts myTD">
                    <bar-item
                      v-show="item.bars > 0"
                      :number="300"
                      :size="item.bars"
                      :color="item.color"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Expert:innentool -->
      <v-row>
        <v-col class="d-flex align-center justify-center px-7 py-0">
          <v-chip
            dark
            color="#012e87"
            class="pa-3 overflow_chip"
            href="https://wie-gross-wird-der-bundestag.de/expertinnentool/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="../assets/eye.svg" class="eye" />
            <div class="ml-2 text-left lato">
              Sie wollen Ihr eigenes Szenario entwickeln? Das geht in unserem
              <b
                class="text-decoration-underline text-white"
              >Expert:innentool</b>
            </div>
          </v-chip>
        </v-col>
      </v-row>
      <!-- * letzter Datensatz -->
      <v-row class="py-0">
        <v-col class="py-0">
          <v-card-text class="grey--text text-center lato small pb-0">Wahlergebnis vom {{ date }}.</v-card-text>
        </v-col>
      </v-row>
      <!-- logos -->
      <v-row class="px-16">
        <v-col cols="12" md="4" class="d-flex flex-column align-center justify-center py-2">
          <v-card-text class="grey--text lato small py-2">Idee & Konzeption von</v-card-text>
          <img src="../assets/default.svg" class="logo1_small" />
        </v-col>
        <v-col cols="12" md="4" class="d-flex flex-column align-center justify-center py-2">
          <v-card-text class="grey--text lato small py-2">Die Daten werden bereitgestellt von</v-card-text>
          <img src="../assets/logo1.png" class="logo2_small" />
        </v-col>
        <v-col cols="12" md="4" class="d-flex flex-column align-center justify-center py-2">
          <v-card-text class="grey--text lato small py-2">Technische Realisierung von</v-card-text>
          <img src="../assets/af-2018-logo-cmyk-on-white.svg" class="AF-2018-Logo-cmyk_onWhite" />
        </v-col>
      </v-row>
      <!-- links -->
      <v-row>
        <v-col class="mt-4">
          <a
            class="footer_link px-2"
            href="https://wie-gross-wird-der-bundestag.de/impressum/"
          >Impressum</a>
          <a
            class="footer_link px-2"
            href="https://wie-gross-wird-der-bundestag.de/datenschutz/"
          >Datenschutz</a>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import AnimateNumber from "./AnimateNumber.vue";
import BarItem from "./BarItem.vue";
import BundestagChart from "./BundestagChart.vue";
import InfoText from "./InfoText.vue";

export default {
  name: "PrognoseWidget",
  components: {
    AnimateNumber,
    BarItem,
    BundestagChart,
    InfoText,
  },
  data() {
    return {
      barNumber: 0,
      baseSize: 200,
      size: 200,
      number: 0,
      date: "26.09.2021",
      activeBtn: 0,
      parteien: [
        {
          name: "CDU/CSU",
          second_votes: null,
          all_seats: null,
          bars: null,
          color: "#1a1a18",
        },
        {
          name: "SPD",
          second_votes: null,
          all_seats: null,
          bars: null,
          color: "#e4200e",
        },
        {
          name: "AfD",
          second_votes: null,
          all_seats: null,
          bars: null,
          color: "#009adf",
        },
        {
          name: "FDP",
          second_votes: null,
          all_seats: null,
          bars: null,
          color: "#ffdd00",
        },
        {
          name: "DIE LINKE",
          second_votes: null,
          all_seats: null,
          bars: null,
          color: "#e5007d",
        },
        {
          name: "GRÜNE",
          second_votes: null,
          all_seats: null,
          bars: null,
          color: "#009647",
        },
        {
          name: "Sonstige",
          second_votes: "",
          all_seats: null,
          bars: null,
          color: "#ffa500",
        },
        {
          name: "Gesamt",
          second_votes: null,
          all_seats: 598,
          class: "bold",
        },
      ],
      headers: [
        {
          text: "Partei",
          align: "start",
          value: "name",
        },
        {
          text: "Wahlergebnis",
          value: "second_votes",
        },
        {
          text: "Sitze gesamt",
          value: "all_seats",
        },
        {
          text: " ",
          value: "bars",
        },
      ],
      selected: [],
      chartData: {
        datasets: [
          {
            data: [200, 131, 95, 96, 61, 219, 1],
            backgroundColor: [
              "#e5007d",
              "#e4200e",
              "#009647",
              "#1a1a18",
              "#ffdd00",
              "#009adf",
              "#ffa500",
            ],
            borderWidth: 0,
          },
        ],
      },
      json: "",
      loading: false,
      dataReady: false,
      isMobile: false,
      infoText: 0,
      btnText: {
        first: "Wahlergebnis 2021",
        second: "L-Bundestag",
        third: "XL-Bundestag",
        fourth: "XXL-Bundestag",
      },
      btnSize: "13em",
    };
  },
  created() {
    const html = document.documentElement;
    html.setAttribute("lang", "de");
  },
  async mounted() {
    await this.getElectionDeData(0);
    this.dataReady = true;
    this.loading = false;
    this.convertToChart();
    this.size = this.baseSize;
    this.$refs.illu.style.position = "relative";
    this.$refs.shadow.style.maxWidth = `${this.size}px`;
    this.$nextTick(() => {
      this.$refs.chart.$el.style.width = `${this.size + 100}px`;
    });
    this.infoText = 0;
  },
  watch: {
    async selected(newVal) {
      this.loading = true;
      const coll = document.getElementsByClassName("illu");
      const coll2 = document.getElementsByClassName("schatten");
      const coll3 = document.getElementsByClassName("behind");
      switch (newVal) {
        case 0:
          this.size = this.baseSize;
          coll[0].style.position = "relative";
          await this.getElectionDeData(0);
          this.loading = false;
          this.convertToChart();
          coll2[0].style.maxWidth = `${this.size}px`;
          coll3[0].style.width = `${this.size + 100}px`;
          this.number = this.sumAllSeats();
          this.parteien[7].all_seats = this.number;
          this.setBarNumber();
          this.infoText = 0;
          break;
        case 1:
          this.size = Math.ceil(this.baseSize * 1.4);
          coll[0].style.position = "absolute";
          await this.getElectionDeData(1);
          this.loading = false;
          this.convertToChart();
          coll2[0].style.maxWidth = `${this.size}px`;
          coll3[0].style.width = `${this.size + 100}px`;
          this.number = this.sumAllSeats();
          this.parteien[7].all_seats = this.number;
          this.setBarNumber();
          this.infoText = 1;
          break;
        case 2:
          this.size = Math.ceil(this.baseSize * 1.6);
          coll[0].style.position = "absolute";
          await this.getElectionDeData(2);
          this.loading = false;
          this.convertToChart();
          coll2[0].style.maxWidth = `${this.size}px`;
          coll3[0].style.width = `${this.size + 100}px`;
          this.number = this.sumAllSeats();
          this.parteien[7].all_seats = this.number;
          this.setBarNumber();
          this.infoText = 2;
          break;
        case 3:
          this.size = Math.ceil(this.baseSize + this.baseSize * 0.75);
          coll[0].style.position = "absolute";
          await this.getElectionDeData(3);
          this.loading = false;
          this.convertToChart();
          coll2[0].style.maxWidth = `${this.size}px`;
          coll3[0].style.width = `${this.size + 100}px`;
          await this.getElectionDeData(3);
          this.loading = false;
          this.convertToChart();
          this.number = this.sumAllSeats();
          this.parteien[7].all_seats = this.number;
          this.setBarNumber();
          this.infoText = 3;
          break;
        default:
          break;
      }
    },
    baseSize(newVal) {
      const coll = document.getElementsByClassName("illu");
      const coll2 = document.getElementsByClassName("schatten");
      const coll3 = document.getElementsByClassName("behind");
      switch (this.selected) {
        case 0:
          this.size = newVal;
          coll[0].style.position = "relative";
          coll2[0].style.maxWidth = `${this.size}px`;
          coll3[0].style.width = `${this.size + 100}px`;
          break;
        case 1:
          this.size = Math.ceil(newVal * 1.4);
          coll[0].style.position = "absolute";
          coll2[0].style.maxWidth = `${this.size}px`;
          coll3[0].style.width = `${this.size + 100}px`;
          break;
        case 2:
          this.size = Math.ceil(newVal * 1.6);
          coll[0].style.position = "absolute";
          coll2[0].style.maxWidth = `${this.size}px`;
          coll3[0].style.width = `${this.size + 100}px`;
          break;
        case 3:
          this.size = Math.ceil(newVal + newVal * 0.75);
          coll[0].style.position = "absolute";
          coll2[0].style.maxWidth = `${this.size}px`;
          coll3[0].style.width = `${this.size + 100}px`;
          break;
        default:
          break;
      }
    },
  },
  methods: {
    async getElectionDeData(arg) {
      switch (arg) {
        case 0:
          await axios
            .get(
              "https://www.election.de/cgi-bin/btw21_tool.pl?output_json=1&splitting_btw21=1"
            )
            .then((res) => (this.json = res));
          break;
        case 1:
          await axios
            .get(
              "https://www.election.de/cgi-bin/btw21_tool.pl?output_json=1&splitting_l=1"
            )
            .then((res) => (this.json = res));
          break;
        case 2:
          await axios
            .get(
              "https://www.election.de/cgi-bin/btw21_tool.pl?output_json=1&splitting_xl=1"
            )
            .then((res) => (this.json = res));
          break;
        case 3:
          await axios
            .get(
              "https://www.election.de/cgi-bin/btw21_tool.pl?output_json=1&splitting_xxl=1"
            )
            .then((res) => (this.json = res));
          break;
        default:
          await axios
            .get(
              "https://www.election.de/cgi-bin/btw21_tool.pl?output_json=1&splitting_ausgang=1"
            )
            .then((res) => (this.json = res));
          break;
      }
    },
    convertToChart() {
      if (this.json) {
        // asign all_seats to the parties
        this.setAllSeats();
        // assign second_votes to the parties
        this.setSecondVotes();
        // set length of bar dependend on all_seats
        this.setBars();

        this.setChart();
      }
    },
    setAllSeats() {
      this.parteien[0].all_seats = this.json.data["CDU/CSU"]["Sitze gesamt"];
      this.parteien[1].all_seats = this.json.data.SPD["Sitze gesamt"];
      this.parteien[2].all_seats = this.json.data.AfD["Sitze gesamt"];
      this.parteien[3].all_seats = this.json.data.FDP["Sitze gesamt"];
      this.parteien[4].all_seats = this.json.data["DIE LINKE"]["Sitze gesamt"];
      this.parteien[5].all_seats = this.json.data.GRÜNE["Sitze gesamt"];
      this.parteien[6].all_seats = this.json.data.Sonstige["Sitze gesamt"];
    },
    sumAllSeats() {
      let sum = 0;
      for (let i = 0; i < 7; i++) {
        sum += this.parteien[i].all_seats;
      }
      return sum;
    },
    setSecondVotes() {
      this.parteien[0].second_votes = this.json.data["CDU/CSU"].Zweitstimmen;
      this.parteien[1].second_votes = this.json.data.SPD.Zweitstimmen;
      this.parteien[2].second_votes = this.json.data.AfD.Zweitstimmen;
      this.parteien[3].second_votes = this.json.data.FDP.Zweitstimmen;
      this.parteien[4].second_votes = this.json.data["DIE LINKE"].Zweitstimmen;
      this.parteien[5].second_votes = this.json.data.GRÜNE.Zweitstimmen;
      this.parteien[6].second_votes = this.json.data.Sonstige.Zweitstimmen;
    },
    setBars() {
      this.parteien[0].bars = this.json.data["CDU/CSU"]["Sitze gesamt"];
      this.parteien[1].bars = this.json.data.SPD["Sitze gesamt"];
      this.parteien[2].bars = this.json.data.AfD["Sitze gesamt"];
      this.parteien[3].bars = this.json.data.FDP["Sitze gesamt"];
      this.parteien[4].bars = this.json.data["DIE LINKE"]["Sitze gesamt"];
      this.parteien[5].bars = this.json.data.GRÜNE["Sitze gesamt"];
      this.parteien[6].bars = this.json.data.Sonstige["Sitze gesamt"];
    },
    getHighestBar() {
      let highestBar = 0;
      for (const element of this.parteien) {
        if (element.bars > highestBar) highestBar = element.bars;
      }
      return highestBar;
    },
    setBarNumber() {
      this.barNumber = this.getHighestBar();
    },
    setChart() {
      this.chartData.datasets[0].data = [
        this.parteien[4].all_seats,
        this.parteien[1].all_seats,
        this.parteien[5].all_seats,
        this.parteien[0].all_seats,
        this.parteien[3].all_seats,
        this.parteien[2].all_seats,
        this.parteien[6].all_seats,
      ];
    },
    bundestagClass(item) {
      return item.all_seats >= 598 ? "bold" : "";
    },
    setInfoText(text) {
      this.infoText = text;
    },
    onResize() {
      if (window.innerWidth < 600) {
        this.baseSize = 150;
        this.changeBtnText(true);
        this.isMobile = true;
      } else {
        this.baseSize = 200;
        this.changeBtnText(false);
        this.isMobile = false;
      }
      this.btnSize = "13em !important";
    },
    changeBtnText(bool) {
      if (bool) {
        this.btnText.first = "Wahlergebnis 2021";
        this.btnText.second = "L";
        this.btnText.third = "XL";
        this.btnText.fourth = "XXL";
      } else {
        this.btnText.first = "Wahlergebnis 2021";
        this.btnText.second = "L-Bundestag";
        this.btnText.third = "XL-Bundestag";
        this.btnText.fourth = "XXL-Bundestag";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* lato-regular - latin */
/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Lato/lato-v17-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../assets/fonts/Lato/lato-v17-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/Lato/lato-v17-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/Lato/lato-v17-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Lato/lato-v17-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/Lato/lato-v17-latin-regular.svg#Lato") format("svg"); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Lato/lato-v17-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../assets/fonts/Lato/lato-v17-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/Lato/lato-v17-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/Lato/lato-v17-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Lato/lato-v17-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/Lato/lato-v17-latin-700.svg#Lato") format("svg"); /* Legacy iOS */
}

/* merriweather-regular - latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Merriweather/merriweather-v22-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../assets/fonts/Merriweather/merriweather-v22-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../assets/fonts/Merriweather/merriweather-v22-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/Merriweather/merriweather-v22-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/Merriweather/merriweather-v22-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/Merriweather/merriweather-v22-latin-regular.svg#Merriweather")
      format("svg"); /* Legacy iOS */
}

/* merriweather-700 - latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Merriweather/merriweather-v22-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../assets/fonts/Merriweather/merriweather-v22-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../assets/fonts/Merriweather/merriweather-v22-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/Merriweather/merriweather-v22-latin-700.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/Merriweather/merriweather-v22-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/Merriweather/merriweather-v22-latin-700.svg#Merriweather")
      format("svg"); /* Legacy iOS */
}

html,
body {
  hyphens: auto;
  overflow-x: hidden;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 12px;
  }

  .btn-txt {
    font-size: 1.5rem !important;
    width: 12em !important;
  }

  .illu {
    width: 170px !important;
  }

  .schatten {
    width: 315px !important;
  }

  .behind {
    max-width: 410px !important;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 2.1rem !important;
  }
}

.wrapper td {
  border-bottom: none !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 4px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 2.1rem !important;
}

.mytable table tr,
.mytable table td {
  font-size: 1rem !important;
}

.mytable table tr {
  border-bottom: none !important;
}

.mytable table td {
  border-bottom: none !important;
}

.mytable table th {
  font-size: 1rem !important;
  border-bottom: none !important;
  color: #000 !important;
  font-weight: bold !important;
}

.bold {
  font-weight: bold;
}

.h1 {
  font-weight: 900;
  font-family: "Merriweather", sans-serif;
}

.lato {
  font-family: "Lato", sans-serif;
  font-size: 1rem !important;
}

.btn-txt {
  font-size: 0.8rem !important;
}

.welcome {
  color: #1d31d7;
  font-family: "Merriweather", sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.5;
  word-break: normal !important;
}

.logo1_small {
  height: 30px;
  object-fit: contain;
}

.logo2_small {
  width: 49px;
  height: 29px;
  object-fit: contain;
}

.AF-2018-Logo-cmyk_onWhite {
  width: 118px;
  height: 17px;
  object-fit: contain;
}

.tr-header {
  font-size: 1rem !important;
  border-bottom: none !important;
  color: #000 !important;
  font-weight: bold !important;
}

.Overlay {
  position: relative;
  transition: max-width 2s;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ov-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 100px !important;
}

.behind {
  position: absolute;
  z-index: 0;
  align-self: flex-end;
  max-width: 600px;
  transition: width 1s;
}

.illu {
  display: flex;
  position: absolute;
  z-index: 2;
  width: 200px;
  max-width: 200px;
  align-self: flex-end;
}

.schatten {
  transition: max-width 1.5s, opacity 2s;
  object-fit: contain;
  z-index: 1;
  max-width: 450px;
  align-self: flex-end;
}

.noGuts {
  width: 0;
  margin: 0 !important;
  padding: 0 !important;
}

.small {
  font-size: 12px !important;
}

.overflow_chip {
  height: auto !important;
  white-space: normal !important;
}

.footer_link {
  color: #000 !important;
  text-decoration: #000 none;
}

.footer_link:hover,
.footer_link:focus {
  text-decoration: solid underline;
}
</style>
