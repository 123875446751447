<template>
  <div v-if="displayNumber > 0">{{displayNumber}}</div>
  <div v-else>&nbsp;</div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
    },
  },
  data() {
    return {
      displayNumber: 0,
      interval: false,
    };
  },
  created() {
    this.displayNumber = this.number;
  },
  ready() {
    this.displayNumber = this.number ? this.number : 0;
  },
  watch: {
    number() {
      clearInterval(this.interval);

      if (this.number === this.displayNumber) {
        return;
      }

      this.interval = window.setInterval(() => {
        if (this.displayNumber != this.number) {
          let change = (this.number - this.displayNumber) / 10;
          change = change >= 0 ? Math.ceil(change) : Math.floor(change);
          this.displayNumber += change;
        }
      }, 50);
    },
  },
};
</script>
