<template>
  <div>
    <canvas id="BundestagChart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    chartData: Object,
  },
  data() {
    return {
      chart: {
        type: "doughnut",
        data: "",
        options: {
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          cutoutPercentage: 65,
          responsive: true,
          maintainAspectRatio: true,
          tooltips: {
            enabled: false,
          },
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById("BundestagChart");
    this.chart.data = this.chartData;
    new Chart(ctx, this.chart);
  },
};
</script>
