<template>
  <div class="opacity">
    <b>{{ strong }}</b>
    <span>{{ text }}</span>
    (das genaue Splittingszenario finden Sie
    <a href="https://wie-gross-wird-der-bundestag.de/expertinnentool">hier</a>).
    <span>{{ praedikat }}</span>
  </div>
</template>

<script>
export default {
  props: {
    arg: Number,
  },
  data() {
    return {
      show: true,
      intervall: false,
      strong: "Regelgröße 598:",
      text:
        " Die Regelgröße von 598 Abgeordneten wird beim derzeitigen Umfragetrend nur erreicht, " +
        "wenn fast alle Wähler:innen der Grünen auch mit ihrer Erststimme Grün wählen, " +
        "und die Union gleichzeitig mehr Zweitstimmen als Erststimmen bekommt, " +
        "was nach 1953 bei keiner einzigen Bundestagswahl mehr passiert ist ",
      praedikat:
        "Prädikat: Ein Bundestag mit 598 Abgeordneten wäre wünschenswert, " +
        "ist aber derzeit extrem unwahrscheinlich!",
    };
  },
  watch: {
    arg(newVal) {
      const op = document.getElementsByClassName("opacity");
      console.log(op);
      op[0].style.opacity = "0";
      switch (newVal) {
        case 0:
          this.strong = "Regelgröße 598:";
          this.text =
            " Die Regelgröße von 598 Abgeordneten wird beim derzeitigen Umfragetrend nur erreicht, " +
            "wenn fast alle Wähler:innen der Grünen auch mit ihrer Erststimme Grün wählen, " +
            "und die Union gleichzeitig mehr Zweitstimmen als Erststimmen bekommt, " +
            "was nach 1953 bei keiner einzigen Bundestagswahl mehr passiert ist ";
          this.praedikat =
            "Prädikat: Ein Bundestag mit 598 Abgeordneten wäre wünschenswert, " +
            "ist aber derzeit extrem unwahrscheinlich!";
          break;
        case 1:
          this.strong = "L-Bundestag:";
          this.text =
            " Nur wenn beim derzeitigen Umfragetrend lediglich etwa so " +
            "viele FDP-Wähler:innen wie 2017 mit ihrer Erststimme die Union wählen, " +
            "und die Unionswähler:innen zu etwa einem Achtel mit ihrer Erststimme " +
            "die Grünen wählen (was sie noch nie getan haben!), " +
            "würde der Bundestag zumindest nicht größer als der jetzige ";
          this.praedikat =
            " Prädikat: Ein Bundestag mit 685 Abgeordneten wäre deutlich zu groß, " +
            "ist aber derzeit auch eher unwahrscheinlich!";
          break;
        case 2:
          this.strong = "XL-Bundestag:";
          this.text =
            " Würden bei jetzigen Umfragetrend knapp die Hälfte der FDP-Wähler:innen " +
            "mit ihrer Erststimme die Union wählen (2013 haben das 63 Prozent gemacht!), " +
            "und wird die Union von gewohnt vielen ihrer Wähler:innen mit beiden Stimmen gewählt, " +
            "würde sich der Bundestag auf mehr als 850 Mandate aufblähen ";
          this.praedikat =
            " Prädikat: Ein Bundestag mit 799 Abgeordneten wäre inakzeptabel, " +
            "ist aber derzeit nicht unwahrscheinlich!";
          break;
        case 3:
          this.strong = "XXL-Bundestag:";
          this.text =
            " Würde etwa jede/r Fünfte Wähler:in der Grünen mit der Erststimme " +
            "die Union wählen (2017 hat das jede/r Siebte gemacht!), und gut 60 Prozent " +
            "aller FDP-Wähler:innen mit ihrer Erststimme die Union wählen (genau wie 2013), " +
            "dann bläht sich der Bundestag beim derzeitigen Umfragetrend auf 963 Mandate auf ";
          this.praedikat =
            " Prädikat: Ein Bundestag mit fast 900 Abgeordneten wäre extrem problematisch, " +
            "ist aber derzeit nicht auszuschließen!";
          break;
        default:
          break;
      }
      clearInterval(this.intervall);
      let valOp = 0;
      this.interval = window.setInterval(() => {
        if (valOp < 1) {
          valOp += 0.1;
          op[0].style.opacity = valOp;
        }
      }, 150);
    },
  },
};
</script>

<style scoped>
.opacity {
  opacity: 1;
}
</style>
